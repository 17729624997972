//获取社群列表
const getAssociaListUrl = `/gateway/hc-neighbour/association/getAssListForMini`;
//获取社群分类
const getAssociaKindsListUrl = `/gateway/hc-neighbour/association/getInitInfo`;
//删除社群分类
const getDeleteListUrl = `/gateway/hc-neighbour/association/delete`;
// 新增社群分类
const getKindsUrl = `/gateway/hc-neighbour/association/add`;
//  更新社群分类
const updateKindsUrl = `/gateway/hc-neighbour/association/update`;
//  获取社群分类详情
const getKindsDetailUrl = `/gateway/hc-neighbour/association/getDetailInfoById`;
// ---------------------------------------------------------------------------------
// 根据ID获取社群详情
const getAssDetailForMiniUrl = `/gateway/hc-neighbour/association/getAssDetailForMini`;
// 申请加入社群
const applyJoinAssUrl = `/gateway/hc-neighbour/association/applyJoinAss`;
// 判断用户是否是群主
const checkIsOwnerUrl = `/gateway/hc-neighbour/association/checkIsOwner`;
// 获取社群成员
const getAssUserByIdUrl = `/gateway/hc-neighbour/association/getAssUserById`;
// 获取我的社群
const getMyAssInfoUrl = `/gateway/hc-neighbour/myAssociation/getMyAssInfo`;
// 获取社群申请消息
const getApplyMessageUrl = `/gateway/hc-neighbour/myAssociation/getApplyMessage`;
// 获取社群申请消息详情
const getApplyDetailsUrl = `/gateway/hc-neighbour/myAssociation/getApplyDetails`;
// 社群申请审核
const auditUrl = `/gateway/hc-neighbour/myAssociation/audit`;
// 提交申请更新社群
const commitApplyUrl = `/gateway/hc-neighbour/myAssociation/commitApply`;
// 解散社群
const dissolveAssociationUrl = `/gateway/hc-neighbour/myAssociation/dissolveAssociation`;
// 移除群成员
const removeMembersUrl = `/gateway/hc-neighbour/myAssociation/removeMembers`;
// 移除社群申请消息
const removeAppInfoUrl = `/gateway/hc-neighbour/myAssociation/removeAppInfo`;
// 社群活动创建
const createActivityUrl = `/gateway/hc-neighbour/associationActivity/createActivity`;
// 获取社群活动列表
const getAssActivityListUrl = `/gateway/hc-neighbour/associationActivity/getAssActivityList`;
// 获取社群活动详情
const getDetailsUrl = `/gateway/hc-neighbour/associationActivity/getDetails`;
// 参加社群活动或取消
const joinOrQuitUrl = `/gateway/hc-neighbour/associationActivity/joinOrQuit`;
// 查询社群动态列表
const selectDynamicsUrl = `/gateway/hc-neighbour/topic/getAssDynamics`;
// 删除动态
const deleteDynamicsUrl = `/gateway/hc-neighbour/topic/deleteTopic`;
// 发布动态
const issueDynamicsUrl = `/gateway/hc-neighbour/topic/addTopic`;
// 置顶动态
const stickyUrl = `/gateway/hc-neighbour/topic/stickAssociationDynamic`;
// 获取用户信息接口
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
// 判断社群更新审核状态
const checkStatusUrl = `/gateway/hc-neighbour/myAssociation/checkStatus`;
// 新增社群
const addAssInfoForMiniUrl = `/gateway/hc-neighbour/association/addAssInfoForMini`;
export {
  getAssociaListUrl,
  getAssociaKindsListUrl,
  getDeleteListUrl,
  getKindsUrl,
  getKindsDetailUrl,
  updateKindsUrl,
  // ---------------------
  getAssDetailForMiniUrl,
  applyJoinAssUrl,
  checkIsOwnerUrl,
  getAssUserByIdUrl,
  getMyAssInfoUrl,
  getApplyMessageUrl,
  getApplyDetailsUrl,
  auditUrl,
  commitApplyUrl,
  dissolveAssociationUrl,
  removeMembersUrl,
  removeAppInfoUrl,
  createActivityUrl,
  getAssActivityListUrl,
  getDetailsUrl,
  joinOrQuitUrl,
  selectDynamicsUrl,
  deleteDynamicsUrl,
  issueDynamicsUrl,
  stickyUrl,
  userInfoUrl,
  checkStatusUrl,
  addAssInfoForMiniUrl,
};
